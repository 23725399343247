import React from 'react';

import GlobalStyle, { variables } from '../styles/GlobalStyles'

import StyledTableOfContents from '../styles/case-study/TableOfContents'
import CaseStudyStyles from '../styles/case-study/CaseStudy'
import CaseStudyHero from '../styles/case-study/HeroBanner'
import { UserJourneyStevenSlider, UserJourneyJuliaSlider } from '../components/slider/apple-music';
import RelatedCarousel from '../components/caseStudyComponents/relatedCarousel';
import CTAButton from '../styles/case-study/CTAButton';
import { Grid } from '../styles/case-study/Grid';
import { Card, BoxCard } from '../styles/case-study/Card';
import EmphasisBlock from '../styles/case-study/EmphasisBlock';
import Persona from '../styles/case-study/Persona';
import { Summary, SummaryItem } from '../styles/case-study/Summary';

import SEO from '../components/seo'
import Nav from '../components/nav'
import Wrapper from '../styles/Wrapper'
import { Section } from '../styles/Section';
import Footer from '../components/footer';
import HugeCallout from '../styles/case-study/HugeCallout';


const IndexPage = () => {
  return (
    <>
      <GlobalStyle />
      {/* eslint-disable-next-line */}
      <SEO title="Jonathan Hoy - Apple Music" />
      <header>
        <Nav casestudy={true} />
        <StyledTableOfContents brandColor900={`${variables.amBrandColor900}`} brandColor100={`${variables.amBrandColor100}`} className='sidebar'>
          <Wrapper>
            <ol>
              <li><a href="#overview"><span className='num'>1. </span>Overview</a></li>
              <li><a href="#problem"><span className='num'>2. </span>Problem</a></li>
              <li><a href="#solution"><span className='num'>3. </span>Solution</a></li>
              <li><a href="#research"><span className='num'>4. </span>Research</a></li>
              <li><a href="#audit"><span className='num'>5. </span><span className='shorten'>Design </span> Audit</a></li>
              <li><a href="#decisions"><span className='num'>6. </span><span className='shorten'>Design </span> Decisions</a></li>
              <li><a href="#testing"><span className='num'>7. </span>Testing</a></li>
              <li><a href="#updates"><span className='num'>8. </span>Updates</a></li>
              <li><a href="#closing"><span className='num'>9. </span>Closing</a></li>
            </ol>
          </Wrapper>
        </StyledTableOfContents>
      </header>
      <main className='case-study'>
        <CaseStudyHero src="/case-study/apple-music/hero-banner.png" alt="Banner with the text Discovering Music through Friends and Connecting with Friends through Music."></CaseStudyHero>
        <Section>
          <Wrapper>
            <CaseStudyStyles brandColor900={`${variables.amBrandColor900}`} brandColor100={`${variables.amBrandColor100}`} heading={`${variables.black}`}>
              <div className='text-container' id="overview">
                <h2><span className='number'>1</span>Overview</h2>
                <Summary columns="3">
                  <SummaryItem rows="1 / 2" columns="1 / 4">
                    <p className='heading'>The Project</p>
                    <p className='description'>For this individual project I identified the problem that users feel the current Apple Music social experience is not fulfilling their needs of wanting to discuss music with friends and seeing what music friends are listening to.</p>
                    <p className='description'>Through multiple rounds of usability testing and feedback loops I have designed a solution that encourages users to connect with friends who have a similar taste in music as well as discover new music from friends.</p>
                  </SummaryItem>
                  <SummaryItem rows="2 / 3" columns="1 / 2">
                    <p className='heading'>My Roles</p>
                    <ul className='list no-bullet small-mt'>
                      <li><p>UX Researcher</p></li>
                      <li><p>UX/UI Designer</p></li>
                    </ul>
                  </SummaryItem>
                  <SummaryItem rows="2 / 3" columns="2 / 3">
                    <p className='heading'>Timeline</p>
                    <p className='description'>3 months</p>
                  </SummaryItem>
                  <SummaryItem rows="2 / 3" columns="3 / 4">
                    <p className='heading'>Tools</p>
                    <ul className='list no-bullet small-mt'>
                      <li><p>Figma</p></li>
                      <li><p>Miro</p></li>
                    </ul>
                  </SummaryItem>
                </Summary>
              </div>


              <div className='text-container' id="problem">
                <h2><span className='number'>2</span>The Problem</h2>
                <p>This case study is for a class project where a brief was given. I chose to focus on streaming service discovery, specifically choosing Apple Music. The <span className='bold'>initial problem to solve was to streamline the process of finding and consuming content</span>. As a starting point to guide my research I decided to focus on the social aspects of streaming entertainment and how addressing social aspects may improve the metrics of finding and consuming content.</p>
              </div>

              <div className='text-container' id="solution">
                <span className='section-anchor' aria-disabled="true"></span>
                <h2><span className='number'>3</span>The Solution</h2>
                <h3 className='subheading'>Discovering Music through Friends and Connecting with Friends through Music</h3>
                <div className='video-container'>
                  <video controls autoPlay muted loop playsinline>
                    <source 
                      src="https://xgjzloifyvgpbmyonaya.supabase.co/storage/v1/object/public/files/Qo1LYvU6C1/V1zt0oMawi"
                      type="video/webm"
                    />
                    <source 
                      src="https://xgjzloifyvgpbmyonaya.supabase.co/storage/v1/object/public/files/Qo1LYvU6C1/V1zt0oMawi" 
                      type="video/mp4"
                    />
                  </video>
                </div>
                <CTAButton primary={`${variables.amBrandColor100}`} secondary={`${variables.amBrandColor900}`}>
                  <a href="https://www.figma.com/proto/1CTJpKlsJMfI8Vq3GBv9kv/Apple-Music?page-id=1937%3A4372&type=design&node-id=1937-11234&viewport=225%2C583%2C0.19&t=h4z8HJXD47WUvt17-1&scaling=scale-down&starting-point-node-id=1937%3A11234&mode=design" target="_blank" rel="noreferrer">View interactive prototype <span className='arrow'>→</span></a>
                </CTAButton>
              </div>

              <div className='text-container' id="research">
                <h2><span className='number'>4</span>Research</h2>
                <h3 className='subheading'>Research Goals</h3>
                <Grid columns="2">
                  <BoxCard>
                    <p className='headline'>1</p>
                    <p className='description'>If the social aspect of Apple Music is important for music discovery and/or a means to connect with friends.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>2</p>
                    <p className='description'>If music is shared with friends to provide music recommendation or if music is shared with others to update them on what you are listening to.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>3</p>
                    <p className='description'>If the social aspect exists in a physical or virtual space.</p>
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>User Research</h3>
                <p>I conducted qualitative research in the form of <span className='bold'>user interviews</span> with study participants. The criteria to be a participant was:</p>
                <ol>
                  <li><p>Actively subscribes to Apple Music.</p></li>
                  <li><p>Familiar with the existing social features of Apple Music.</p></li>
                </ol>
                <p>The three participants I interviewed:</p>
                <Grid columns="3">
                  <BoxCard>
                    <p className='heading'>Participant 1</p>
                    <ul className='list'>
                      <li><p>Age: 29</p></li>
                      <li><p>Female</p></li>
                      <li><p>Works in the insurance industry</p></li>
                    </ul>
                  </BoxCard>
                  <BoxCard>
                    <p className='heading'>Participant 2</p>
                    <ul className='list'>
                      <li><p>Age: 27</p></li>
                      <li><p>Male</p></li>
                      <li><p>Works in the tech industry</p></li>
                    </ul>
                  </BoxCard>
                  <BoxCard>
                    <p className='heading'>Participant 3</p>
                    <ul className='list'>
                      <li><p>Age: 25</p></li>
                      <li><p>Female</p></li>
                      <li><p>Works in the retail industry</p></li>
                    </ul>
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Findings from Research</h3>
                <Grid columns="2">
                  <Card>
                    <p className='heading'>Music discovery</p>
                    <p className='description'>Most people prefer to discover music on their own and are not likely to recommend music to friends but they are still interested in seeing what music friends are listening to. The social features of Apple Music are still desired for reasons other than music discovery.</p>
                  </Card>
                  <Card>
                    <p className='heading'>Music recommendations</p>
                    <p className='description'>People say they don't share or recommend music all that often but they still do share to some degree. Some people simply share to start a conversation about music and tend to do so with people who share a similar taste in music.</p>
                  </Card>
                  <Card>
                    <p className='heading'>Listening to music</p>
                    <p className='description'>People listen to music in many situations in many environments. When engaged in an activity listening to music tends to be background noise, however in certain situations (commuting, relaxing to name a few) listening to music is a more conscious activity.</p>
                  </Card>
                  <Card>
                    <p className='heading'>Sources of music discovery</p>
                    <p className='description'>Music discovery comes from a variety of sources such as social media, other entertainment mediums, and Apple Music personalization. The emphasis is that people tend to want to discover music on their own, not where music is discovered.</p>
                  </Card>
                  <Card>
                    <p className='heading'>Lengthy social flow</p>
                    <p className='description'>People find the number of steps/screens it takes to view friends' profiles annoying and lengthy resulting in people not checking profiles or using social features as often.</p>
                  </Card>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Crafting Personas Informed by Research</h3>
                <p>From my research I synthesized that there were two unique attitudes towards both music discovery and music recommendation. I think there are such differences because people have different perspectives and values towards the social aspect of music. Because of the stark differences in attitudes, behaviours, and motivations I felt it was necessary to create two personas.</p>
                <Grid columns="1">
                  <Persona>
                    <div className='avatar'>
                      <img src="/case-study/apple-music/persona-steven.png" alt=""></img>
                      <p className='name'>Steven</p>
                    </div>
                    <div className='goals'>
                      <p className='heading'>Goals</p>
                      <ul className='list'>
                        <li><p><span className='bold'>Discover</span> artists that he enjoys on his own and adds their music to his library</p></li>
                        <li><p>Talks about music with friends who he knows have a <span className='bold'>similar taste</span> in music</p></li>
                      </ul>
                    </div>
                    <div className='painpoints'>
                      <p className='heading'>Pain Points</p>
                      <ul className='list'>
                        <li><p>When he wants to check his friends’ profiles he feels it <span className='bold'>takes too long</span></p></li>
                      </ul>
                    </div>
                  </Persona>
                  <Persona>
                    <div className='avatar'>
                      <img src="/case-study/apple-music/persona-julia.png" alt=""></img>
                      <p className='name'>Julia</p>
                    </div>
                    <div className='goals'>
                      <p className='heading'>Goals</p>
                      <ul className='list'>
                        <li><p>Discover artists and music from a <span className='bold'>variety of sources</span>; including her friends</p></li>
                      </ul>
                    </div>
                    <div className='painpoints'>
                      <p className='heading'>Pain Points</p>
                      <ul className='list'>
                        <li><p>Thinks the way to see friends’ profiles is <span className='bold'>not intuitive</span> (when she is curious to see friend activity)</p></li>
                      </ul>
                    </div>
                  </Persona>
                </Grid>
                <p>Steven represents an interesting discovery I made during user research. He prefers to discover music on his own. He’s on one end of the music recommendation spectrum where he doesn’t even listen to them. Over time his friends have come to know better than to send him any recommendations. This attitude is not from a place of arrogance - Steven simply enjoys and is content with the music and artists that he’s discovered on his own.</p>
                <p>To fully enjoy the music listening experience Julia is open to discovering music from many sources, including her friends. That is what sets Julia apart from Steven, the desire and willingness to receive music recommendations from friends.</p>
                <p>In the grand scheme of the music listening experience most music is self-discovered. That is true for both personas. Participants revealed that even if they do receive music recommendations, the majority of their music libraries are still self-discovered. Within the frame of this study, that is an important distinction to make regarding the social aspects of Apple Music.</p>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Jobs To Be Done Framework</h3>
                <div className='image-container'>
                  <img src="/case-study/apple-music/jobs-to-be-done-steven.png" alt=""></img>
                </div>
                <div className='image-container'>
                  <img src="/case-study/apple-music/jobs-to-be-done-julia.png" alt=""></img>         
                </div>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Empathy Mapping</h3>
                <Grid columns="2" mt="0">
                  <div className='image-container'>
                    <img src="/case-study/apple-music/empathy-mapping-steven.png" alt=""></img>
                  </div>
                  <div className='image-container'>
                    <img src="/case-study/apple-music/empathy-mapping-julia.png" alt=""></img>         
                  </div>         
                </Grid>
              </div>

              <div className='text-container' id="audit">
                <h2><span className='number'>5</span>Design Audit</h2>
                <h3 className='subheading'>Competitive Analysis</h3>
                <div className='image-container'>
                  <img src="/case-study/apple-music/competitive-analysis.png" alt=""></img>         
                </div>  
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Heuristic Evaluation</h3>
                <div className='image-container'>
                  <img src="/case-study/apple-music/heuristic-summary.png" alt=""></img>         
                </div> 
                <p>Read my detailed evaluation: <a href="https://drive.google.com/file/d/1IWJeysGEQkQBaDVAGbQGjiAx4vMVjhfL/view?usp=sharing" target="_blank" rel="noreferrer" className='inline'>Apple Music Heuristic Evalution Report <span className='arrow'>→</span></a></p>
              </div>


              <div className='text-container'>
                <h3 className='subheading'>Problem Statement</h3>
                <EmphasisBlock brandColor100={`${variables.amBrandColor100}`} brandColor900={`${variables.amBrandColor900}`}>
                  <p>Apple Music users that enjoy listening to music, discovering new music, and talking to their friends about music have the problem that they want to know what music their friends are listening to. My solution should deliver the ability for users to learn about their friends' music tastes and discuss with friends the music that they have in common.</p>
                </EmphasisBlock>
              </div>

              <div className='text-container' id="decisions">
                <h2><span className='number'>6</span>Design Decisions</h2>
                <h3 className='subheading'>Goals of my Solution</h3>
                <Grid columns="2">
                  <BoxCard>
                    <p className='headline'>1</p>
                    <p className='heading'>Make the friends feature a prominent feature.</p>
                    <p className='description'>The existing friends list is not an easily discoverable feature and the flow to view the friends list is lengthy.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>2</p>
                    <p className='heading'>Let the user know music in common with friends.</p>
                    <p className='description'>Research tells us that people want to engage with friends who share the same taste in music.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>3</p>
                    <p className='heading'>Showcase music that friends are listening to.</p>
                    <p className='description'>Allow the user to discover new music and satisfy curiosity.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>4</p>
                    <p className='heading'>Let the user message friends.</p>
                    <p className='description'>Let friends connect and discuss music together.</p>
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>How my Proposed Solution solves the Problem</h3>
                <Grid columns="2" align="center">
                  <Card>
                    <p className='heading'>Commonality and Connectivity</p>
                    <p className='description'>People like to connect with friends that have a similar taste in music. People tend to know what music their friends prefer and would send or receive music recommendations or talk about music with friends who would enjoy that music.</p>
                  </Card>
                  <div className='asset small'>
                    <img src="/case-study/apple-music/people-collection-1.png" alt=""></img>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <Grid columns="2" align="center">
                  <div className='asset small'>
                    <img src="/case-study/apple-music/people-collection-2.png" alt=""></img>
                  </div>
                  <Card>
                    <p className='heading'>Curiosity and Discovery</p>
                    <p className='description'>People are curious about what music their friends listen to and like to enrich their own music library with music from friends. Sometimes people are simply curious about their friends’ music activity and that could lead to music discovery as well.</p>
                  </Card>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Creating User Journeys</h3>
                <h4 className='subheading'>Steven's User Journey</h4>
                <UserJourneyStevenSlider />
              </div>

              <div className='text-container'>
              <h4 className='subheading'>Julia's User Journey</h4>
                <UserJourneyJuliaSlider />
                <p>The important distinction in the journeys of the two users who have different goals is the use of the Music Feed. I decided to create two tabs to easily separate the music that the user listens to (commonality) and all the music that the user's friends listen to (discovery).</p>
              </div>

              <div className='text-container' id="testing">
                <h2><span className='number'>7</span>User Testing and Iterating</h2>
                <h3 className='subheading'>Let’s See What Works (First Iteration)</h3>
                <img src="/case-study/apple-music/iteration-1.png" alt=""></img>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Applying User Feedback (Second Iteration)</h3>
                <h4 className='subheading'>Second Iteration Screens</h4>
                <img src="/case-study/apple-music/iteration-2.png" alt=""></img>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Second Iteration Notes</h4>
                <img src="/case-study/apple-music/iteration-2-annotations-1.png" alt=""></img>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Now We’re Getting Somewhere (Third Iteration)</h3>
                <h4 className='subheading'>Third Iteration Screens</h4>
                <img src="/case-study/apple-music/iteration-3.png" alt=""></img>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Third Iteration Notes</h4>
                <img src="/case-study/apple-music/iteration-3-annotations-1.png" alt=""></img>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Why? Why? Why? (Fourth Iteration)</h3>
                <HugeCallout brandColor={`${variables.amBrandColor100}`} className='emphasis'>Why?</HugeCallout>
                <p>Issues of discovery were addressed, user flows were simplified, and consistency was created. With this iteration I took a step back and asked <span className='bold'>why?</span> I wanted to scrutinize every design decision I’ve made to this point to ensure that my design contained only what is necessary to complete my user flows. From copy to micro-interactions to entire pages, I questioned if everything was really necessary.</p>
              </div>
              
              <div className='text-container'>
                <h4 className='subheading'>Fourth Iteration Screens</h4>
                <img src="/case-study/apple-music/iteration-4.png" alt=""></img>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Fourth Iteration Notes</h4>
                <img src="/case-study/apple-music/iteration-4-annotations-1.png" alt=""></img>
              </div>

              <div className='text-container'>
                <img src="/case-study/apple-music/iteration-4-annotations-2.png" alt=""></img>
              </div>

              <div className='text-container'>
                <img src="/case-study/apple-music/iteration-4-annotations-3.png" alt=""></img>
              </div>
            </CaseStudyStyles>
          </Wrapper>
        </Section>


        <Section className='inverted-case-study'>
          <Wrapper>
            <CaseStudyStyles brandColor900={`${variables.amBrandColor900}`} brandColor100={`${variables.amBrandColor100}`} heading={`${variables.black}`}>
              <div className='text-container' id="updates">
                <h2><span className='number'>8</span>2023 Updates</h2>
                <h3 className='subheading'>More familiarity with Apple's Human Interface Guidelines</h3>
                <p>Now that I have a greater understanding of the importance of adhering to the constraints of an established design language, I understand that my original solution would not be acceptable. My solution starts with adding a new <span className='bold'>tab item</span> to the Tab Bar in Apple Music. Apple's <a href="https://developer.apple.com/design/human-interface-guidelines/tab-bars" target="_blank" rel="noreferrer" className='inline'>Human Interface Guidelines <span className='arrow'>→</span></a> advises:</p>
                <p><span className='italic'>“Use the minimum number of tabs required to help people navigate your app. Each additional tab increases the complexity of your app, making it harder for people to locate information. Aim for a few tabs with short titles or icons to avoid crowding and causing labels to truncate. In general, <span className='bold'>use up to five tabs in iOS</span> and up to six in visionOS, iPadOS, and tvOS.”</span></p>
                <img src="/case-study/apple-music/tab-bars.png" alt="" className='mt-2'></img>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>iOS Apple Music updates</h3>
                <p>When I was working on this project, <span className='bold'>iOS 14</span> was the latest public release. Now, <span className='bold'>iOS 16</span> is available and Apple Music has been updated. The product now includes the user’s profile on every tab. This change positively affects my solution as a pain point was that it was a lengthy flow to view a user's friend's list.</p>
                <img src="/case-study/apple-music/user-profile.png" alt=""></img>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Design Decisions and Updated Prototype</h3>
                <p>Understanding that there must sometimes be compromise in design, this is my proposed change to my design. The friends tab will move to the user’s profile which mimics the current flow. I propose to update this screen with the user’s profile. The existing Followers and Following list would be updated with my design and features. From the user’s profile page they can also access extra settings that were previously available when tapping on the user’s profile.</p>
                <h4 className='subheading'>Real life iOS 14 flow</h4>
                <p>In iOS 14 the user's profile badge was only on the Listen Now page. This limited availability contributed to the difficulty of navigating to the user's profile.</p>
                <div className='image-container'>
                  <img src="/case-study/apple-music/user-flow-ios-14.png" alt=""></img>
                </div>
                <h4 className='subheading'>Real life iOS 16 flow</h4>
                <p>iOS 16 updated Apple Music to add the user's profile badge on each screen making it much more accessible and convenient to access.</p>
                <div className='image-container'>
                  <img src="/case-study/apple-music/user-flow-ios-17.png" alt=""></img>
                </div>
                <h4 className='subheading'>My Solution Flow Update</h4>
                <p>A simple change I am proposing is to have the outcome of tapping the user's profile badge take the user directly to the user's profile screen, instead of account settings. It's not very intuitive to access one's profile through settings, it should be the other way around.</p>
                <div className='image-container'>
                  <img src="/case-study/apple-music/user-flow-solution.png" alt=""></img>
                </div>
                <div className='video-container'>
                  <video controls autoPlay muted loop playsinline>
                    <source 
                      src="https://binsta.dev/api/v1/files/O39S35SPpa/transform?format=webm&size=lg&quality=md" 
                      type="video/webm" 
                    />
                    <source 
                      src="https://binsta.dev/api/v1/files/O39S35SPpa/transform?format=mp4&size=lg&quality=md" 
                      type="video/mp4"  
                    />
                  </video>
                </div>
              </div>
            </CaseStudyStyles>
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <CaseStudyStyles brandColor900={`${variables.amBrandColor900}`} brandColor100={`${variables.amBrandColor100}`} heading={`${variables.black}`}>
              <div className='text-container' id="closing">
                <h2><span className='number'>9</span>Closing</h2>
                <h3 className='subheading'>Lessons Learned</h3>
                <Grid columns="1">
                  <BoxCard>
                    <p className='headline'>🔎</p>
                    <p className='heading'>Research is for defining a problem, not defining a solution</p>
                    <p className='description'>I learned the importance of not trying to be solution-oriented during user research. I admit to formulating ideas of possible solutions at that stage. In retrospect, how could I possibly design a solution for a problem that I didn’t know yet? As I conducted research synthesis and gained a deeper understanding of the true problems of users I realized that my initial ideas for solutions were for what I perceived to be the problem, not what the research tells me is the true problem, and thus the problem of the user.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>📝</p>
                    <p className='heading'>Focusing on a problem statement</p>
                    <p className='description'>I also learned the importance of identifying and focusing on a problem statement. Once I had sketched, wireframed, and prototyped designs I lost sight of the initial problem statement that I had defined. Through my design iterations and testing I was able to come back to the problem statement and ask myself if my designs helped to solve that problem.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>🔄</p>
                    <p className='heading'>Solutions are iteration-based</p>
                    <p className='description'>Another key takeaway is that the first solution will not be the final solution. It sounds like common sense but in practice I now understand that at the onset of the first prototype, a lot is still unknown. Usability testing proved to be extremely important helping to craft a solution that solves the problem. Looking back now, my final solution is very different from my initial solution since I now have a greater understanding of the problem.</p>
                  </BoxCard>
                </Grid>
              </div>
            </CaseStudyStyles>
          </Wrapper>
        </Section>
        <RelatedCarousel doNotInclude="apple-music"/>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default IndexPage;
