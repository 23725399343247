import styled from 'styled-components';
import { variables } from '../GlobalStyles';

const HugeCallout = styled.p`
  font-size: 20rem;
  font-weight: 700;
  text-align: center;
  &.emphasis {
    color: ${props => props.brandColor};
  }
  @media (max-width: ${variables.lg}) {
    font-size: 12rem;
  }
  @media (max-width: ${variables.sm}) {
    font-size: 8rem;
  }
`;

export default HugeCallout;