import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AdditionalSliderStyles from "../../styles/case-study/SliderStyles";

const settings = {
  arrows: true,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

class UserJourneyStevenSlider extends React.Component {
  render() {
    return (
      <div>
        <AdditionalSliderStyles>
          <Slider {...settings}>
            <div>
              <img src="/case-study/apple-music/user-journey-steven-1.png" alt=""></img>
            </div>
            <div>
              <img src="/case-study/apple-music/user-journey-steven-2.png" alt=""></img>
            </div>
            <div>
              <img src="/case-study/apple-music/user-journey-steven-3.png" alt=""></img>
            </div>
          </Slider>
        </AdditionalSliderStyles>
      </div>
    );
  }
};

class UserJourneyJuliaSlider extends React.Component {
  render() {
    return (
      <div>
        <AdditionalSliderStyles>
          <Slider {...settings}>
            <div>
              <img src="/case-study/apple-music/user-journey-julia-1.png" alt=""></img>
            </div>
            <div>
              <img src="/case-study/apple-music/user-journey-julia-2.png" alt=""></img>
            </div>
            <div>
              <img src="/case-study/apple-music/user-journey-julia-3.png" alt=""></img>
            </div>
          </Slider>
        </AdditionalSliderStyles>
      </div>
    );
  }
};

export { 
  UserJourneyStevenSlider,
  UserJourneyJuliaSlider,
}